define('account-opening/services/equifax', ['exports', 'twapi/models/kba-submission'], function (exports, _kbaSubmission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      and = _Ember$computed.and,
      bool = _Ember$computed.bool,
      not = _Ember$computed.not,
      get = Ember.get,
      service = Ember.inject.service,
      Service = Ember.Service,
      set = Ember.set;


  var EQUIFAX_DIT = 'Equifax/Dit';

  exports.default = Service.extend({
    accountOpening: service(),

    twapiEndpoints: service(),

    questionnaire: null,

    hasQuestionnaire: bool('questionnaire'),

    dkbaResponse: null,

    existingResult: null,

    hasExistingResult: bool('existingResult'),

    hasReviewed: false,

    hasNotReviewed: not('hasReviewed'),

    showReviewPrompt: and('needsToReview', 'hasNotReviewed'),

    needsToReview: bool('hasNoPendingOrApprovedApplication'),

    hasPendingOrApprovedApplication: alias('accountOpening.customer.hasPendingOrApprovedApplication'),

    hasNoPendingOrApprovedApplication: not('hasPendingOrApprovedApplication'),

    isQuestionnaireSubmitted: false,

    questions: alias('questionnaire.questions'),

    fetchKycResult: function fetchKycResult() {
      var result = get(this, 'twapiEndpoints').getLastKycResult();

      set(this, 'existingResult', result);

      return result;
    },
    checkCustomerIdentity: function checkCustomerIdentity() {
      if (get(this, 'hasPendingOrApprovedApplication')) {
        this.submitEquifaxDit();
      } else {
        this.initiateKba();
      }
    },
    setHasReviewed: function setHasReviewed() {
      if (get(this, 'needsToReview')) {
        set(this, 'hasReviewed', true);
      }
    },
    submitEquifaxDit: function submitEquifaxDit() {
      get(this, 'twapiEndpoints').submitKyc({ vendorNames: [EQUIFAX_DIT], force: true });
    },
    initiateKba: function initiateKba() {
      get(this, 'twapiEndpoints').initiateKba().then(this._handleDkbaResponse.bind(this));
    },
    submitKbaQuestionnaire: function submitKbaQuestionnaire() {
      var _this = this;

      var kbaSubmission = _kbaSubmission.default.create({
        questionAnswers: get(this, 'questions'),
        correlationId: get(this, 'dkbaResponse.correlationId'),
        authenticationId: get(this, 'dkbaResponse.kba.authenticationId')
      });
      get(this, 'twapiEndpoints').submitKba(kbaSubmission.serialize()).then(function () {
        set(_this, 'isQuestionnaireSubmitted', true);
      });
    },
    _handleDkbaResponse: function _handleDkbaResponse(result) {
      set(this, 'dkbaResponse', result);

      var kba = get(result, 'kba');
      if (get(kba, 'hasQuestionnaire')) {
        set(this, 'questionnaire', get(kba, 'questionnaire'));
      }
    }
  });
});