define('account-opening/controllers/step/review', ['exports', 'raven', 'ember-restless', 'ember-decorators/object', 'twapi/utils/validation', 'twapi/utils/error', 'twapi/utils/computed', 'twapi/utils/slug'], function (exports, _raven, _emberRestless, _object, _validation, _error, _computed, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Controller = Ember.Controller,
      htmlSafe = Ember.String.htmlSafe,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      not = _Ember$computed.not,
      or = _Ember$computed.or,
      get = Ember.get,
      service = Ember.inject.service,
      isPresent = Ember.isPresent,
      run = Ember.run,
      set = Ember.set;


  var MAX_WAIT_TIME = 12000;

  var clickModel = _emberRestless.default.Model.extend({
    'clicked': _emberRestless.default.attr('boolean')
  }).reopen(_validation.build({
    'clicked': { exclusion: { in: [false] } }
  }));

  exports.default = Controller.extend((_dec = (0, _object.observes)('application.status'), (_obj = {
    accountOpening: service(),

    jointAccount: service(),

    jointAccountContinuation: service(),

    planAccess: service(),

    referral: service(),

    service: service('step'),

    session: service(),

    twapiToaster: service(),

    editPlan: false,

    isSendingApplication: false,

    hasSignature: false,

    signature: null,

    transitionIfStillProcessing: null,

    agreed: alias('agree.clicked'),

    isAccessEditable: not('accountOpening.isJointAccountContinuation'),

    isAccountTypeEditable: not('accountOpening.isJointAccountContinuation'),

    sessionToken: alias('session.response.sessionToken'),

    config: _computed.default.registry.resolve('config:environment'),

    customer: alias('accountOpening.customer'),

    hasTrustedContact: alias('accountOpening.hasTrustedContact'),

    trustedContact: alias('accountOpening.trustedContact'),

    application: alias('accountOpening.application'),

    entity: alias('accountOpening.entity'),

    currentPlan: alias('model.application.accessPlan'),

    marginType: alias('planAccess.marginType'),

    beneficiaries: alias('model.application.accountBeneficiaries'),

    needsSignature: or('customer.isForeign', 'accountOpening.isEntityAccount'),

    showBeneficiaries: or('application.accountType.isAnyIra', 'accountOpening.isTrustAccount'),

    isAnyJoint: or('accountOpening.isJointAccount', 'accountOpening.isJointAccountContinuation'),

    applicationStatusObserver: function applicationStatusObserver() {
      var application = get(this, 'application');
      if (!get(application, 'isLoaded') || get(application, 'isSubmitted')) {
        return;
      }

      set(this, 'isSendingApplication', false);
      run.cancel(get(this, 'transitionIfStillProcessing'));
      if (get(application, 'isSendFailed')) {
        var alert = htmlSafe('Your application was rejected for the following reason(s):<div class="toast-message-rejection-reasons">' + get(application, 'externalReason') + '.</div>Please correct this and re-submit.');
        get(this, 'twapiToaster').alert(alert);
      } else {
        this.transitionToRoute('done');
      }
    },
    agree: function agree() {
      return clickModel.create({
        'clicked': false
      });
    },


    actions: {
      toggleEditPlan: function toggleEditPlan() {
        this.toggleProperty('editPlan');
      },
      planSelected: function planSelected(plan) {
        set(this, 'currentPlan', plan);
        set(this, 'editPlan', false);
      },
      toggleAgree: function toggleAgree() {
        this.toggleProperty('agreed');
        set(this, 'customer.agreedToTerms', get(this, 'agreed'));
      },
      signed: function signed(signature) {
        set(this, 'hasSignature', true);
        set(this, 'signature', signature);
      },
      clearSignature: function clearSignature() {
        set(this, 'hasSignature', false);
      },
      submit: function submit(_step) {
        var _this = this;

        var incompleteStep = get(this, 'service.nextIncompleteStep');
        if (incompleteStep) {
          return this.transitionToRoute(get(incompleteStep, 'route'));
        }
        set(this, 'isSendingApplication', true);

        _kmq.push(['record', 'Submitted Application']);

        var accountOpeningService = get(this, 'accountOpening');

        var savePromises = [accountOpeningService.saveCustomerSuitability().then(this._saveCustomer.bind(this)), accountOpeningService.saveTrustedContact(), accountOpeningService.saveSecurityQuestions()];

        if (get(this, 'needsSignature')) {
          savePromises.push(this._uploadSignature(get(this, 'signature')));
        }

        // TODO: [KT] deferring this persistence to the end since we don't have a workflow for picking up where we left off
        if (get(accountOpeningService, 'isEntityAccount')) {
          savePromises.push(accountOpeningService.saveEntity().then(function () {
            set(_this, 'application.entityId', get(_this, 'entity.id'));

            return accountOpeningService.saveEntitySuitability();
          }));
        }

        Promise.all(savePromises).then(function () {
          var application = accountOpeningService.saveApplication();

          set(_this, 'transitionIfStillProcessing', run.later(function () {
            if (get(_this, 'application.isSubmitted')) {
              _this.transitionToRoute('done');
            }
          }, MAX_WAIT_TIME));

          return application;
        }).then(function (application) {
          get(_this, 'referral').clearReferral();

          set(_this, 'application', application);
        }).catch(function (errors) {
          set(_this, 'isSendingApplication', false);

          var fields = get(errors, 'error.fields');

          if (isPresent(fields)) {
            var step = get(_this, 'service').stepForFieldNames(Object.keys(fields));

            if (isPresent(get(fields, 'hashedTaxNumber'))) {
              // TODO: [KT] temporary messaging until we have a workflow for dealing with existing entities

              get(_this, 'twapiToaster').alert('Entity/Trust already exists.  Please contact tastytrade support.');
            } else if (step) {
              get(_this, 'twapiToaster').alert(_error.parseFieldValidations(errors).join('. '));

              return _this.transitionToRoute(step.get('route'));
            } else {
              get(_this, 'twapiToaster').alert(_error.parseFieldValidations(errors).join('. '));
            }
          } else {
            var errorMessage = get(errors, 'message') || get(errors, 'error.message');
            get(_this, 'twapiToaster').alert(errorMessage);
          }
        });
      }
    },

    _uploadSignature: function _uploadSignature(signature) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        var url = 'customers/me/documents/async';
        var slug = _slug.SLUGS.SIGNATURE_IMAGE;

        xhr.open('POST', '' + get(_this2, 'config.twapi.baseURL') + url + '/' + slug, true);
        xhr.setRequestHeader('Authorization', _this2.get('sessionToken'));
        xhr.onload = function () {
          if (this.status === 200 || this.status === 201) {
            resolve();
          } else {
            _raven.default.captureMessage('Error uploading signature for application');
            reject(Ember.Object.create({
              error: {
                message: 'Error uploading signature'
              }
            }));
          }
        };
        xhr.onerror = reject;

        var formData = new FormData();
        formData.append('file', signature);

        xhr.send(formData);
      });
    },
    _saveCustomer: function _saveCustomer() {
      return get(this, 'accountOpening').saveCustomer();
    }
  }, (_applyDecoratedDescriptor(_obj, 'applicationStatusObserver', [_dec], Object.getOwnPropertyDescriptor(_obj, 'applicationStatusObserver'), _obj), _applyDecoratedDescriptor(_obj, 'agree', [_object.computed], Object.getOwnPropertyDescriptor(_obj, 'agree'), _obj)), _obj))).reopen(_validation.build({
    signed: { presence: true },
    hasSignature: { inclusion: { in: [true] } }
  }));
});